import * as React from 'react';
import Spotlight from './Spotlight';

export interface CharacterBioProps {
  image: JSX.Element;
  title: string;
  bio: string;
}

const CharacterBio: React.FC<CharacterBioProps> = ({ image, title, bio }: CharacterBioProps) => (
  <Spotlight image={image}>
    <h4>{title}</h4>
    <div dangerouslySetInnerHTML={{ __html: bio }} />
  </Spotlight>
);

export default CharacterBio;
