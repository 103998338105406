import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';
import Img from 'gatsby-image';
import CharacterBio from '../components/CharacterBio';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutBook: markdownRemark(frontmatter: {title: {eq: "About the Book"}}) {
        html
        frontmatter {
          title
        }
      }
      marcusBio:  markdownRemark(frontmatter: {title: {eq: "Marcus Brown"}}) {
        html
        frontmatter {
          title
        }
      }
      marcusImage: file(relativePath: { eq: "marcus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drewBio:  markdownRemark(frontmatter: {title: {eq: "Drew Salazar"}}) {
        html
        frontmatter {
          title
        }
      }
      drewImage: file(relativePath: { eq: "drew.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      buyNow: markdownRemark(frontmatter: {title: {eq: "Buy Now"}}) {
        html
        frontmatter {
          title
        }
      }
    }
  `);

  const MarcusImage = <Img fluid={data.marcusImage.childImageSharp.fluid} />;
  const DrewImage = <Img fluid={data.drewImage.childImageSharp.fluid} />;

  return (
    <Layout>
      <SEO title="Directorate 51" keywords={['science fiction', 'marcus brown', 'drew salazar']} />
      <Section>
        <SectionHeader className="major">
          <h2>
            About the Book
          </h2>
        </SectionHeader>
        <div dangerouslySetInnerHTML={{ __html: data.aboutBook.html }} />
      </Section>
      <Section>
        <SectionHeader>
          <h2>
            Characters
          </h2>
        </SectionHeader>
        <CharacterBio image={MarcusImage} title="Marcus Brown" bio={data.marcusBio.html} />
        <CharacterBio image={DrewImage} title="Drew Salazar" bio={data.drewBio.html} />
      </Section>
      <Section>
        <SectionHeader>
          <h2>Buy Now</h2>
        </SectionHeader>
        <div dangerouslySetInnerHTML={{ __html: data.buyNow.html }} />
      </Section>
    </Layout>
  );
};

export default IndexPage;
