import * as React from 'react';

export interface SpotlightProps {
  className?: string;
  image?: React.ReactElement;
}

const Spotlight: React.FC<SpotlightProps> = ({ children, className, image }) => (
  <section className={`spotlight ${className}`}>
    {image && (
      <div className="image">
        {image}
      </div>
    )}
    <div className="content">
      {children}
    </div>
  </section>
);

export default Spotlight;
