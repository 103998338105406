import * as React from 'react';

export interface SectionHeaderProps {
  className?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ children, className }) => (
  <header className={className}>
    {children}
  </header>
);

export default SectionHeader;
