import * as React from 'react';
import classnames from 'classnames';

export interface SectionProps {
  className?: string;
}

const Section: React.FC<SectionProps> = ({ children, className }) => (
  <section className={classnames('wrapper', className)}>
    <div className="inner">
      {children}
    </div>
  </section>
);

export default Section;
